import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/dist/tiny-slider.css'
const imagesLoaded = require('imagesloaded')

const tinySlider = {
  init(){
    document.querySelectorAll('.slider').forEach((slider) => {
      let container = slider.closest('.slider-container')
      let id = slider.id
      let items = parseInt(slider.dataset.sliderItems || 3)
      let center = (slider.dataset.sliderCenter == 'true' || false)
      let responsive_options = slider.dataset.sliderResponsive || '{}'

      let positionControls = function(info){
        let sliderControls = info.controlsContainer
        imagesLoaded(slider, function(){
          let sliderHeight = slider.querySelector('img').offsetHeight
          let sliderControlsHeight = sliderControls.offsetHeight
          sliderControls.style.top = (sliderHeight - sliderControlsHeight) / 2 + 'px'
          container.style.visibility = 'visible';
        })
      }

      let default_options = {
        container: `#${id}`,
        nav: false,
        items: (items == 1 ? items : items - 0.5),
        center: center,
        // slideBy: 1,
        mouseDrag: true,
        gutter: 10,
        edgePadding: 0,
        lazyload: true,
        swipeAngle: false,
        responsive: JSON.parse(responsive_options),
      }

      let controls_options = {}
      if (document.getElementById(`${id}_controls`)) {
        container.style.visibility = 'hidden';
        controls_options = {
          controlsContainer: `#${id}_controls`,
          prevButton: `#${id}_controls .slider-control-prev`,
          nextButton: `#${id}_controls .slider-control-next`,
          onInit: positionControls
        }
      }

      tns({...default_options, ...controls_options})
    })
  }
}

export default tinySlider
